import { Centered } from "./Join";

export default function About() {
    return (
        <div>
            <div style={{display:'grid',placeItems:'center',marginTop:'7em'}}>
                <div style={{width:'40em'}}>
                    <p><h3><strong>ABOUT</strong></h3></p>
                    <p>This archive contains documentation of individuals of different ages and expertise levels preparing couscous. Each record in the archive is a collection of parameters analyzed by image recognition algorithms. The data analysis represents a unique expression that cannot be reproduced. In fact, there is no one way to prepare couscous or any other dish, for that matter. The recipe is a guiding principle and framework for the individual that allows for self-expression, physical and emotional experience.</p>
                    <p>In the intersection between culinary and technology, the latter is mostly used for measurement, optimization, and reproducing the cooking process without human touch, with an emphasis on the outcome. In this project, the machine observes the cooking process and allows an analysis of parameters representing the physical-emotional connection in the act. The archive seeks to shift the focus from the product to the experience, from the definitive recipe (words and measurements) to soft parameters such as hand movement, body posture, rhythm, and the state of mind in relation to the movement, thus examining how all of these connect to a unique and personal couscous occurrence.</p>
                    <br/>
                    <p><h3><strong>THE COUSCOUS MAKERS</strong></h3></p>
                    <p>Through their personal stories, the couscous makers have created a window into the rich culture of their countries of origin, raising unique social issues for both the Israeli space and broader issues of preserving tradition versus globalization and technological development. They address issues of ethnicity, and cultural heritage when the culture they came from was treated with disregard and contempt. They touch on the role of women throughout multiple generations, questioning if the motivation for family cooking has changed and in what way. Finally, the younger voices tell a story of reclaiming their traditions and origin, defining their new contemporary practice.</p>
                    <br/>
                    <p><h3><strong>READING THE DATA</strong></h3></p>
                    <p>The visualized data is an analysis of what we define as “soft parameters”.
                    Through these soft parameters, we aim to measure intent rather than movement accuracy. While the data is generated by image recognition algorithms it shines a light on the human aspects.</p>
                    <br/>
                    <div style={{marginLeft:'3em'}}>
                        <p><span className="marked">Pose</span> Using a skeletal view, shows the muscle effort and origin of movement. Does the hand movement originate from the hips? the shoulder?</p>
                        <p><span className="marked">Gesture</span> Shows the choreography of the hands in relation to the material and tools. </p>
                        <p><span className="marked">Rhythm</span> Body tempo, originating in the hips, translated to sound. </p>
                        <p><span className="marked">State of Mind</span> Facial expressions showing levels of focus and satisfaction, is there a correlation between focus and satisfaction? Where are the “heart” moments?</p>
                    </div>
                    <br/>
                    <p>The parameters are shown as window displays you can turn on and off. They can be juxtaposed to ask questions looking into the mind-body connection in the process of making. </p>
                    <br/>
                    <p>We invite you to drag, position, question, listen and play!</p>

                    {/* vimeo video, clean without extra buttons or logos */}
                    <div style={{marginTop:'3em'}}>
                        <iframe src="https://player.vimeo.com/video/828642719?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=2f2b2a2d1b" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="Couscous Archive"></iframe>
                    </div>
                </div>
            </div>

            <style jsx>{`
                body{
                    background-color: magenta;
                }
                .marked{
                    background-color: white;
                    color: magenta;
                    font-weight: 600;
                }
            `}</style>
        </div>
    )
}